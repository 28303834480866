









import { Component, Vue, Prop } from 'vue-property-decorator'
import { Contact } from '../models/contact'

@Component({})
export default class EmailLink extends Vue {
  @Prop({ type: String, default: '' })
  readonly address!: string

  @Prop({ type: Boolean, default: true })
  readonly showIcon!: boolean
}
