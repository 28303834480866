






import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
  }
})
export default class SalesRootPage extends Vue {
  public mounted () {

  }

  public created () {
  }

  public activated () {
  }

  public updated () {
  }

  public destroyed () {
  }

  public deactivated () {

  }
}
