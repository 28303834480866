










































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import SalesUserNote from '@/modules/sales/models/salesuser/note'
import { ListsEnum } from '@/modules/shared/enums'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { AuthState } from '@/modules/auth/store'
import { AppState } from '@/stores/appStore'
import { CustomerState } from '@/modules/customers/store'
import Rules from '@/plugins/validations'
import { SalesUsersNoteApi } from '../../api/salesusers'
import { SalesState } from '../../store'

@Component
export default class SalesUserNoteForm extends Vue {
  private errorMessage = ''
  private saving = false
  private valRules: Rules = Rules
  private noErrors = true

  @Prop({ required: true, default: 0 })
  private salesId!: number

  @Prop({ default: () => new SalesUserNote() })
  private note!: SalesUserNote

  private localNote = new SalesUserNote()

  private noteCategories = mapObjectVuetifySelect(AppState.listsByName[ListsEnum.SALES_NOTE_CATEGORIES]!, true).sort((a, b) => a.text.localeCompare(b.text))

  public get rowsByViewSize () {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
        return 5
      case 'sm':
        return 5
      case 'md':
        return 15
      case 'lg':
        return 20
      case 'xl':
        return 25
    }
  }

  public handleSubmit (): void {
    if (this.saving) {
      return
    }

    if (!(this.$refs.noteForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    this.saving = true
    this.errorMessage = ''

    SalesState.SaveUserNote(this.localNote)
      .then((note: SalesUserNote) => {
        this.$emit('note:updated', note)
        this.handleClose()
      })
      .catch((err: string) => {
        this.errorMessage = err
      })
      .finally(() => {
        this.saving = false
      })
  }

  public handleClose (): void {
    this.$emit('dialog:close')
  }

  public handleReset (): void {
    this.localNote = new SalesUserNote({ salesId: this.salesId })
  }

  public get mode () {
    return this.localNote.id ? 'Edit' : 'Add'
  }

  public beforeMount () {
    this.localNote = this.note.clone()
  }
}
