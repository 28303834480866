



















































































































































































































import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator'
import { StateAbbreviationsEnum, TimezonesEnum, ListsEnum } from '@/modules/shared/enums'
import { IStringIndexed, IStringDictionary, INumericDictionary } from '@/modules/shared/types'
import { AppUserTypes } from '@/modules/shared/lists'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { AuthState } from '@/modules/auth/store'
import { SalesState } from '@/modules/sales/store'
import SalesPersonModel from '@/modules/sales/models/salesuser'
import TaxModel from '@/modules/sales/models/salesuser/taxid'
import AgreementModel from '@/modules/sales/models/salesuser/agreement'
import UserFileModel from '@/modules/sales/models/salesuser/file'
import { SalesUserApi, SalesUsersAgreementApi, SalesUsersTaxIdApi, SalesUsersNoteApi, SalesUsersFileApi } from '@/modules/sales/api/salesusers'
import Rules from '@/plugins/validations'
import { AppState } from '@/stores/appStore'
import FscAutocomplete from '@/modules/shared/components/Autocomplete.vue'
import { mask } from 'vue-the-mask'
import { File as FileModel } from '@/modules/shared/models/file'
import moment from 'moment'

@Component({
  components: {
    'fsc-autocomplete': FscAutocomplete
  },
  directives: {
    mask
  }
})
export default class SalesUserForm extends Vue {
  @PropSync('user', {
    default: () => (new SalesPersonModel()),
    required: true
  })
  private person!: SalesPersonModel

  @Prop({ default: false })
  private isDialog!: boolean

  private statii = mapObjectVuetifySelect(SalesPersonModel.statii, true)
  private states = AppState.dividedStateSelect

  private version: string = '';
  private taxId: string = '';
  private createCrmUser: number = 0

  private files: Array<{ reading: boolean; completed: number; file: FileModel }> = []
  private categories = mapObjectVuetifySelect(AppState.listsByName[ListsEnum.SALES_FILE_CATEGORIES]!, true)

  private errorMessage: string = ''
  private saving: boolean = false
  private valRules: Rules = Rules

  public get readOnly () {
    if (this.canEditUser) {
      return false
    }
    return true
  }

  public get canEditUser () {
    return this.person.id ? AuthState.user.isAllowed('SALES.USERS.EDIT') : AuthState.user.isAllowed('SALES.USERS.CREATE')
  }

  public get canControlPayments () {
    return AuthState.user.isAllowed('SALES.USERS.PAYMENTS.CONTROL')
  }

  public get canViewPaymentInfo () {
    return AuthState.user.isAllowed('SALES.USERS.BANKING.VIEW')
  }

  public get canViewUnmaskedBanking () {
    return this.person.id && AuthState.user.isAllowed('SALES.USERS.BANKING.SENSITIVE')
  }

  public get canEditBanking () {
    return this.person.id
      ? AuthState.user.isAllowed('SALES.USERS.BANKING.EDIT')
      : AuthState.user.isAllowed('SALES.USERS.BANKING.CREATE')
  }

  public get canUploadFiles () {
    return AuthState.user.isAllowed('SALES.USERS.AGREEMENTS.CREATE') || AuthState.user.isAllowed('SALES.USERS.FILES.CREATE')
  }

  public get mode () {
    return this.person.id ? 'Edit' : 'Add'
  }

  public get salesUsers () {
    return SalesState.salesUsers
  }

  public get assignableSales () {
    return Object.values(this.salesUsers).map((u: SalesPersonModel) => {
      return {
        text: u.fullName,
        value: u.id
      }
    })
  }

  private removeFile (index: number) {
    this.files.splice(index, 1)
  }

  private processFileSelection () {
    const chosenFiles: FileList = (this.$refs.fileInput as HTMLInputElement).files!

    if (chosenFiles.length === 0) {
      this.files = this.files.splice(0, this.files.length)
      return
    }

    for (let i = 0; i < chosenFiles.length; i++) {
      this.readFile(chosenFiles[i])
    }
  }

  private readFile (f: File) {
    const reader = new FileReader()

    const newItem: { reading: boolean; completed: number; file: FileModel } = {
      reading: false,
      completed: 0,
      file: new FileModel({ name: f.name, categoryId: 0 })
    }

    reader.onloadstart = (e) => {
      newItem.reading = true

      this.files.push(newItem)
    }

    reader.onprogress = (e) => {
      if (!e.lengthComputable) {
        return
      }

      newItem.completed = Math.round((e.loaded / e.total) * 100)
    }

    reader.onloadend = (e) => {
      newItem.reading = false
      newItem.file.contents = reader.result as string
    }

    reader.readAsBinaryString(f)
  }

  public handleClose () : void {
    (this.$refs.salesPersonForm as Vue & { resetValidation: () => boolean }).resetValidation()

    this.errorMessage = ''

    this.$emit('dialog:close')
  }

  public async handleSubmit () : Promise<void> {
    if (this.readOnly || this.saving) {
      return
    }

    if (!(this.$refs.salesPersonForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    this.saving = true
    this.errorMessage = ''

    const isNew: boolean = !(this.person.id > 0)
    try {
      let person: SalesPersonModel = this.person
      if (this.canEditUser) {
        person = await SalesState.SaveUser(this.person)
        if (isNew) {
          let agreementFileId = null

          if (this.taxId.length) {
            await SalesUsersTaxIdApi.save(person.id, new TaxModel({
              salesId: person.id,
              govId: this.taxId
            }))
          }

          if (this.files.length) {
            for (const f of this.files) {
              const file = new UserFileModel({
                salesId: person.id,
                filename: f.file.filename,
                contents: f.file.contents
              })

              if (f.file.categoryId === 86) {
                const newFile = await SalesUsersAgreementApi.save(person.id, new AgreementModel({
                  salesId: person.id,
                  version: this.version,
                  signedOn: moment.utc().format('YYYY-MM-DD')
                }), file)
                agreementFileId = newFile.id
              } else {
                await SalesUsersFileApi.save(person.id, file)
              }
            }
          } else {
            await SalesUsersAgreementApi.save(person.id, new AgreementModel({
              salesId: person.id,
              version: this.version,
              signedOn: moment.utc().format('YYYY-MM-DD'),
              fileId: agreementFileId ?? undefined
            }))
          }

          this.$emit('salesuser:added', person)

          if (this.createCrmUser) {
            try {
              const crmUser: IStringIndexed = await SalesUserApi.createCrmUser(this.person)
              person.crmId = crmUser.id
              person = await SalesUserApi.save(person)
              this.$emit('salesuser:updated', person)
            } catch (err) {
              throw new Error('The user has been created but failed to create in CRM. Please go back and edit the user record to try again. <br /><br/> Error: ' + err)
            }
          }
        } else {
          this.$emit('salesuser:updated', person)
        }
      }

      this.handleClose()
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.saving = false
    }
  }

  public async handleViewRouting () {
    if (this.person.bankRouting.indexOf('X') !== -1) {
      const resp = await SalesUserApi.load(this.person.id, ['bankRouting'])
      this.person.bankRouting = resp.bankRouting
    }
  }

  public async handleViewBankAccount () {
    if (this.person.bankAccount.indexOf('X') !== -1) {
      const resp = await SalesUserApi.load(this.person.id, ['bankAccount'])
      this.person.bankAccount = resp.bankAccount
    }
  }

  public async handleEmailChange () {
    if ((!this.person.id || !this.person.crmId) && this.person.email.length && Rules.email()(this.person.email) === true) {
      const crmUser: IStringIndexed = await SalesUserApi.getCrmUser(this.person.email)
      this.person.crmId = crmUser.id
      if (crmUser.reportsToId) {
        const boss: SalesPersonModel|undefined = Object.values(this.salesUsers).find((u: SalesPersonModel) => u.crmId === crmUser.reportsToId)
        if (boss !== undefined) {
          this.person.reportsTo = boss.id
        } else {
          this.errorMessage = 'This users supervisor has not yet been setup'
        }
      }
    }
  }

  public async handleCreateCrmUser () {
    try {
      let crmUser: IStringIndexed = await SalesUserApi.getCrmUser(this.person.email)

      if (!crmUser.id) {
        crmUser = await SalesUserApi.createCrmUser(this.person)
      }
      this.person.crmId = crmUser.crmId
      const person = await SalesUserApi.save(this.person)
      this.$emit('salesuser:updated', person)
    } catch (err) {
      this.errorMessage = err
    }
  }

  private validateUniqueEmail (email: string) {
    return Object.values(this.salesUsers).some((p: SalesPersonModel) => p.email === email && p.id !== this.person.id) ? 'A user with this email already exists' : true
  }

  public validateUniqueCrmId () {
    if (this.person.id || this.person.crmId === 0) {
      return true
    }

    const p: SalesPersonModel = Object.values(this.salesUsers).find((p: SalesPersonModel) => p.crmId === this.person.crmId && p.id !== this.person.id)
    if (p) {
      return 'This sales user has already been created under id: (' + p.id + ') ' + p.fullName
    }
    return true
  }
}
