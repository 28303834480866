













import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class PhoneLink extends Vue {
  @Prop({ default: 'phone' })
  readonly icon!: string

  @Prop({ default: '' })
  readonly number!: string

  @Prop({ default: '' })
  readonly extension!: string

  @Prop({ type: Boolean, default: true })
  readonly showIcon!: boolean

  protected get typeAbbrev () {
    return this.icon[0]
  }
}
