
























































































































































































































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import { SalesState } from '@/modules/sales/store'
import NameBar from '@/modules/sales/components/SalesUser/NameBar.vue'
import UserChip from '@/modules/sales/components/UserChip.vue'
import AddressLink from '@/modules/shared/components/AddressLink.vue'
import EmailLink from '@/modules/shared/components/EmailLink.vue'
import PhoneLink from '@/modules/shared/components/PhoneLink.vue'
import SalesUserForm from '@/modules/sales/components/SalesUser.vue'
import SalesPersonModel from '@/modules/sales/models/salesuser'
import { Email as EmailModel } from '@/modules/shared/models/email'
import { PhoneNumber as PhoneModel } from '@/modules/shared/models/phone'
import { SalesUserApi, SalesUsersFileApi } from '@/modules/sales/api/salesusers'
import DialogHost from '@/modules/shared/mixins/DialogHost.vue'
import SalesUserNoteForm from '@/modules/sales/components/SalesUser/Note.vue'
import SalesUserNote from '../models/salesuser/note'
import NoteCard from '@/modules/customers/components/blocks/Note.vue'
import SalesUserFileUploadForm from '../components/SalesUser/FileUpload.vue'
import DownloadFile from '@/modules/shared/mixins/DownloadFile.vue'
import SalesUserFile from '@/modules/sales/models/salesuser/file'
import SalesUserAgreement from '../models/salesuser/agreement'
import SalesUserAgreementForm from '../components/SalesUser/Agreement.vue'

@Component({
  components: {
    namebar: NameBar,
    'user-chip': UserChip,
    'address-link': AddressLink,
    'email-link': EmailLink,
    'phone-link': PhoneLink,
    salesuser: SalesUserForm,
    note: NoteCard,
    'salesuser-note': SalesUserNoteForm,
    'salesuser-agreement': SalesUserAgreementForm,
    'upload-file': SalesUserFileUploadForm
  }
})
export default class SalesUser extends Mixins(DialogHost, DownloadFile) {
  @Prop({ required: true })
  private id!: number

  private fileHeaders: Array<any> = [
    { text: 'ID', align: 'center', sortable: false, value: 'id' },
    { text: 'Type', align: 'left', sortable: true, filterable: false, value: 'categoryLabel' },
    { text: 'Name', align: 'left', sortable: true, value: 'name' },
    { text: 'Uploaded On', align: 'left', sortable: true, value: 'created' }
  ]

  private agreementHeaders: Array<any> = [
    { text: 'ID', align: 'center', sortable: false, value: 'id' },
    { text: 'Signed On', align: 'left', sortable: true, filterable: false, value: 'signedOn' },
    { text: 'Version', align: 'left', sortable: true, value: 'version' },
    { text: 'Status', align: 'left', sortable: true, value: 'statusLabel' }
  ]

  private taxHeaders: Array<any> = [
    { text: 'ID', align: 'center', sortable: false, value: 'id' },
    { text: 'Gov Id', align: 'left', sortable: true, value: 'govId' },
    { text: 'From', align: 'left', sortable: true, value: 'from' },
    { text: 'To', align: 'left', sortable: true, value: 'to' }
  ]

  public get canUnmaskBanking () {
    return this.localPerson.id && AuthState.user.isAllowed('SALES.USERS.BANKING.SENSITIVE')
  }

  private handleEdit () {
    this.handleDialog({
      dialogProps: {
        width: 'auto'
      },
      component: 'salesuser',
      props: {
        user: this.localPerson.clone(),
        isDialog: true
      }
    })
  }

  private handleAddAgreement () {
    if (!this.canAddAgreement) {
      return
    }
    this.handleDialog({
      dialogProps: {
        width: 'auto'
      },
      component: 'salesuser-agreement',
      props: {
        salesUser: this.localPerson,
        agreement: new SalesUserAgreement({ salesId: this.localPerson.id })
      }
    })
  }

  private handleEditAgreement (agreement: SalesUserAgreement) {
    if (!this.canEditAgreement) {
      return
    }

    this.handleDialog({
      dialogProps: {
        width: 'auto'
      },
      component: 'salesuser-agreement',
      props: {
        salesUser: this.localPerson,
        agreement: agreement
      }
    })
  }

  private handleAddNote () {
    this.handleDialog({
      dialogProps: {
        width: 'auto'
      },
      component: 'salesuser-note',
      props: {
        salesId: this.localPerson.id,
        note: new SalesUserNote({ salesId: this.localPerson.id })
      }
    })
  }

  private handleUploadFile () {
    this.handleDialog({
      dialogProps: {
        width: 'auto'
      },
      component: 'upload-file',
      props: {
        salesId: this.localPerson.id
      }
    })
  }

  private async handleDownloadFile (file: SalesUserFile) {
    if (!this.canViewFile) {
      return
    }

    const newFile = await SalesUsersFileApi.load(this.id, file.id, undefined, ['contents'])
    this.downloadFile(newFile.name, newFile.binaryContents, newFile.mimeType)
  }

  public async handleViewRouting () {
    if (this.localPerson.bankRouting.indexOf('X') !== -1) {
      const resp = await SalesUserApi.load(this.localPerson.id, ['bankRouting'])
      this.localPerson.bankRouting = resp.bankRouting
    }
  }

  public async handleViewBankAccount () {
    if (this.localPerson.bankAccount.indexOf('X') !== -1) {
      const resp = await SalesUserApi.load(this.localPerson.id, ['bankAccount'])
      this.localPerson.bankAccount = resp.bankAccount
    }
  }

  public get localPerson () {
    if (!SalesState.salesUsers[this.id]) {
      return new SalesPersonModel()
    }

    return SalesState.salesUsers[this.id]
  }

  public get emailModel () {
    return new EmailModel({ address: this.localPerson.email })
  }

  public get workModel () {
    return new PhoneModel({ number: this.localPerson.work })
  }

  public get cellModel () {
    return new PhoneModel({ number: this.localPerson.cell })
  }

  public get faxModel () {
    return new PhoneModel({ number: this.localPerson.fax })
  }

  public get loading () {
    return SalesState.loading
  }

  public get canEditSalesUser () {
    return AuthState.user.isAllowed('SALES.USERS.EDIT')
  }

  public get canAddTaxId () {
    return AuthState.user.isAllowed('SALES.USERS.TAXIDS.CREATE')
  }

  public get canEditTaxId () {
    return AuthState.user.isAllowed('SALES.USERS.TAXIDS.EDIT')
  }

  public get canAddNote () {
    return AuthState.user.isAllowed('SALES.USERS.NOTES.CREATE')
  }

  public get canEditNote () {
    return AuthState.user.isAllowed('SALES.USERS.NOTES.EDIT')
  }

  public get canAddAgreement () {
    return AuthState.user.isAllowed('SALES.USERS.AGREEMENTS.CREATE')
  }

  public get canEditAgreement () {
    return AuthState.user.isAllowed('SALES.USERS.AGREEMENTS.EDIT')
  }

  public get canAddFile () {
    return AuthState.user.isAllowed('SALES.USERS.FILES.CREATE')
  }

  public get canViewFile () {
    return AuthState.user.isAllowed('SALES.USERS.FILES.VIEW')
  }

  public mounted () {
    SalesState.LoadUser(this.id)
  }
}
