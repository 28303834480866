




import { Component, Vue, Prop } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import StandardSideNav from '@/modules/shared/components/StandardSideNav.vue'
import routeToMenu from '@/router/helper'
import salesRoutes from '@/modules/sales/routes'
import { IStringIndexed } from '@/modules/shared/types'

@Component({
  components: {
    'standard-sidenav': StandardSideNav
  }
})
export default class SideNav extends Vue {
  private get menu () {
    return routeToMenu(this.navKey, salesRoutes)
  }

  private get navKey () {
    if (!(this.$route.matched[0].props as IStringIndexed).nav) {
      return 'root'
    }

    return (this.$route.matched[0].props as IStringIndexed).nav.key as string || 'root'
  }

  private get navWidth () {
    if (!(this.$route.matched[0].props as IStringIndexed).nav) {
      return 175
    }

    return (this.$route.matched[0].props as IStringIndexed).nav.width as string || 175
  }
}
