




































import { Component, Vue, Prop } from 'vue-property-decorator'
import EventBus from '@/plugins/eventbus'
import SalesPersonModel from '@/modules/sales/models/salesuser'

@Component({
  inheritAttrs: true
})
export default class NameBar extends Vue {
  @Prop({ default: true })
  private app!: boolean

  @Prop({ default: 48 })
  private extensionHeight!: number

  @Prop({ default: false })
  private flat!: boolean

  @Prop({ default: false })
  private allowEdit!: boolean

  @Prop({ required: true })
  private user!: SalesPersonModel

  public get typeIcon () {
    return this.user.isBusiness ? 'business' : 'user'
  }
}
