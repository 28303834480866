








































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { AuthState } from '@/modules/auth/store'
import { SalesState } from '@/modules/sales/store'
import Rules from '@/plugins/validations'
import SalesUserAgreement from '../../models/salesuser/agreement'
import UserFileModel from '@/modules/sales/models/salesuser/file'
import { AppState } from '@/stores/appStore'
import { ListsEnum } from '@/modules/shared/enums'
import SalesUser from '../../models/salesuser'

@Component
export default class SalesUserAgreementForm extends Vue {
  private errorMessage = ''
  private saving = false
  private valRules: Rules = Rules
  private noErrors = true

  private menuDateSigned = false
  protected agreementStatii = mapObjectVuetifySelect(SalesUserAgreement.statii, true)

  @Prop({ required: true })
  private salesUser!: SalesUser

  @Prop({ default: () => new SalesUserAgreement() })
  private agreement!: SalesUserAgreement

  private localAgreement = new SalesUserAgreement()

  private files: Array<{ reading: boolean; completed: number; file: UserFileModel }> = []
  private categories = mapObjectVuetifySelect(AppState.listsByName[ListsEnum.SALES_FILE_CATEGORIES]!, true)

  private removeFile (index: number) {
    this.files.splice(index, 1)
  }

  private processFileSelection () {
    const chosenFiles: FileList = (this.$refs.fileInput as HTMLInputElement).files!

    if (chosenFiles.length === 0) {
      this.files = this.files.splice(0, this.files.length)
      return
    }

    for (let i = 0; i < chosenFiles.length; i++) {
      this.readFile(chosenFiles[i])
    }
  }

  private readFile (f: File) {
    const reader = new FileReader()

    const newItem: { reading: boolean; completed: number; file: UserFileModel } = {
      reading: false,
      completed: 0,
      file: new UserFileModel({ salesId: this.localAgreement.salesId, name: f.name, categoryId: 86 })
    }

    reader.onloadstart = (e) => {
      newItem.reading = true

      this.files.push(newItem)
    }

    reader.onprogress = (e) => {
      if (!e.lengthComputable) {
        return
      }

      newItem.completed = Math.round((e.loaded / e.total) * 100)
    }

    reader.onloadend = (e) => {
      newItem.reading = false
      newItem.file.contents = reader.result as string
    }

    reader.readAsBinaryString(f)
  }

  public async handleSubmit () {
    if (this.saving || !this.canSave) {
      return
    }

    if (!(this.$refs.agreementForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    this.saving = true
    this.errorMessage = ''

    try {
      if (this.files.length) {
        for (const f of this.files) {
          const newFile = await SalesState.SaveUserFile({
            file: f.file,
            callback: (e: ProgressEvent) => {
              f.completed = Math.round((e.loaded * 100) / e.total)
              f.reading = f.completed < 100
            }
          })
          if (newFile.categoryId === 86) {
            this.localAgreement.fileId = newFile.id
          }
        }
      }

      const newAgreement = await SalesState.SaveUserAgreement(this.localAgreement)

      this.handleClose()
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.saving = false
    }
  }

  public handleClose () {
    this.$emit('dialog:close')
  }

  public handleReset (): void {
    this.localAgreement = new SalesUserAgreement()
  }

  public get mode () {
    return this.localAgreement.id ? 'Edit' : 'Add'
  }

  public get canSave () {
    if (this.localAgreement.id) {
      return AuthState.user.isAllowed('SALES.USERS.AGREEMENTS.EDIT')
    }
    return AuthState.user.isAllowed('SALES.USERS.AGREEMENTS.CREATE')
  }

  private get agreementFiles () {
    return this.salesUser.files.filter(f => f.categoryId === 86).map(f => {
      return {
        text: f.name,
        value: f.id
      }
    }) || []
  }

  public beforeMount () {
    this.localAgreement = this.agreement.clone()
  }
}
