









import { Component, Vue, Prop } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { SalesState } from '@/modules/sales/store'

@Component({})
export default class UserChip extends Vue {
  @Prop({ required: true })
  private userId!: number

  private get getAppUserName (): string {
    return SalesState.salesNames[this.userId] ?? ''
  }
}
