var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-toolbar',{attrs:{"color":"white"}},[_c('v-toolbar-title',[_vm._v("Manage Sales Users")]),_c('v-spacer'),(_vm.canCreateUser)?_c('v-btn',{attrs:{"color":"primary","icon":"","dark":"","title":"Add Sales Person"},on:{"click":function($event){_vm.dialog=true}}},[_c('fa-icon',{staticClass:"v-icon fa-fw",attrs:{"icon":"user-plus"}})],1):_vm._e()],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.getHeaders,"items":Object.values(_vm.salesPeople),"dense":this.$vuetify.breakpoint.mdAndDown,"mobile-breakpoint":_vm.$vuetify.breakpoint.thresholds.sm,"search":_vm.salesSearch,"sort-by":"companyName","loading":_vm.loading,"loading-text":"Loading...please wait"},on:{"click:row":_vm.handleGotoPerson},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"px-8 px-sm-6",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","offset-sm":"6","offset-md":"8","offset-lg":"9"}},[_c('v-text-field',{staticClass:"mr-2",attrs:{"label":"Search Users","append-icon":"$vuetify.icons.search","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.salesSearch),callback:function ($$v) {_vm.salesSearch=$$v},expression:"salesSearch"}})],1)],1)]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" "),(_vm.isDense && _vm.canEditUser)?_c('v-btn',{attrs:{"icon":"","small":_vm.isDense,"title":"Edit User"}},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_vm._v(" $vuetify.icons.pen ")])],1):_vm._e()]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"dense":"","no-gutters":"","justify":"center"}},[(_vm.canEditUser)?_c('v-switch',{staticStyle:{"margin":"0!important"},attrs:{"color":"success","loading":_vm.toggling[item.id],"dense":"","hide-details":""},on:{"change":function($event){return _vm.handleToggleActive(item)},"click":function($event){$event.stopPropagation();}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}}):_vm._e()],1)]}},{key:"item.reportsTo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.reportsTo(item.reportsTo))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.canEditUser)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_vm._v(" $vuetify.icons.pen ")]):_vm._e()]}},{key:"no-data",fn:function(){return [(_vm.errorMessage)?_c('v-alert',{attrs:{"type":"error","outlined":""}},[_c('p',[_vm._v(_vm._s(_vm.errorMessage))]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.loadData}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("$vuetify.icons.retry")]),_vm._v(" Retry")],1)],1)],1):_vm._e()]},proxy:true}])}),_c('v-dialog',{attrs:{"width":"100%","fullscreen":_vm.isDense},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('salesuser',{attrs:{"user":_vm.editedItem,"isDialog":true},on:{"dialog:close":_vm.handleCloseDialog}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }