


























































import { Component, Vue } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import { SalesState } from '@/modules/sales/store'
import SalesPersonModel from '@/modules/sales/models/salesuser'
import SalesUserForm from '@/modules/sales/components/SalesUser.vue'
import { IStringDictionary, INumericDictionary } from '@/modules/shared/types'

@Component({
  components: {
    salesuser: SalesUserForm
  }
})
export default class SalesPeople extends Vue {
  private dialog: boolean = false
  private salesSearch: string = ''

  private headers: Array<any> = [
    { text: 'ID', align: 'center', sortable: false, value: 'id' },
    { text: 'CRM ID', align: 'center', sortable: false, value: 'crmId' },
    { text: 'Company Name', align: 'left', sortable: true, value: 'companyName' },
    { text: 'First Name', align: 'left', sortable: true, value: 'firstName' },
    { text: 'Last Name', align: 'left', sortable: true, value: 'lastName' },
    { text: 'E-mail', align: 'left', sortable: false, value: 'email' },
    { text: 'Reports To', align: 'left', sortable: true, value: 'reportsTo' },
    { text: 'Active', align: 'center', sortable: false, value: 'active' },
    { text: 'Actions', align: 'center', sortable: false, filterable: false, value: 'action' }
  ]

  private editedItem: SalesPersonModel = new SalesPersonModel()

  private errorMessage: string = ''

  private toggling: IStringDictionary<boolean> = {}

  private get canCreateUser () {
    return AuthState.user.isAllowed('SALES.USERS.CREATE')
  }

  private get canEditUser () {
    return AuthState.user.isAllowed('SALES.USERS.EDIT')
  }

  private get canViewUser () {
    return AuthState.user.isAllowed('SALES.USERS.VIEW')
  }

  private get isDense () {
    return this.$vuetify.breakpoint.smAndDown
  }

  private get salesPeople () {
    return SalesState.salesUsers
  }

  private get loading () {
    return Object.keys(this.salesPeople).length === 0
  }

  private get getHeaders () {
    let headers = this.headers

    if (this.isDense) {
      headers = headers.filter(i => { return i.text !== 'Actions' })
    }

    return headers
  }

  public reportsTo (userId: number) {
    return SalesState.salesNames[userId] ?? ''
  }

  private editItem (selectedItem: SalesPersonModel) {
    this.editedItem = selectedItem.clone()
    this.dialog = true
  }

  private handleCloseDialog () {
    this.dialog = false
    this.editedItem = new SalesPersonModel()
  }

  private handleGotoPerson (item: SalesPersonModel) {
    this.$router.push({ name: 'viewsalesuser', params: { id: item.id.toString() } as any /* Not really supposed to pass complex objects - supposed to use the vuex store */ })
  }

  private async handleToggleActive (item: SalesPersonModel) {
    Vue.set(this.toggling, item.id!, true)
    try {
      const saveModel = item.clone()
      SalesState.SaveUser(saveModel)
    } catch (err) {
      this.errorMessage = err
    } finally {
      Vue.delete(this.toggling, item.id!)
    }
  }

  private mounted () {
    SalesState.UpdateUserList()
  }
}
